import { assert, assertFalse } from '@indieocean/utils'
import _ from 'lodash'

export type SearchHighlight = {text: string; isMatch: boolean}
export const processAlgoliaHighlight = (match: {
  value: string
}): SearchHighlight[] =>
  _.flatten(
    match.value.split('<em>').map((x, i) => {
      const parts = x.split('</em>')
      if (parts.length === 1) {
        assert(i === 0)
        return [{text: parts[0], isMatch: false}]
      } else if (parts.length === 2) {
        return [
          {text: parts[0], isMatch: true},
          {text: parts[1], isMatch: false},
        ]
      } else {
        assertFalse()
      }
    })
  )
