import {API} from '@indieocean/apidef'
import {noCase} from '@indieocean/utils'
import _ from 'lodash'
import React from 'react'
import {AppLink} from './Tools/AppLink'

export const Cover = React.memo(
  ({
    cover,
    href,
    size,
  }: {
    cover?: {url: string; aspectRatio: number} | null
    href?: API.Path
    size: _Size | _Size[]
  }) => {
    const sizes = _.flatMap([size])

    let body = cover ? (
      <img
        src={cover.url}
        width={100}
        height={Math.round(100 / cover.aspectRatio)}
        className={` rounded-md block flex-shrink-0 ${sizes.join(' ')}`}
      />
    ) : (
      <div
        className={`rounded-md block  border-pageFG border ${sizes.join(' ')}
        ${sizes.map(_blankHeight).join(' ')}`}
      />
    )

    if (href) {
      body = <AppLink path={href}>{body}</AppLink>
    }

    return body
  }
)


type _Size =
  | 'w-[30px]'
  | 'w-[35px]'
  | 'w-[45px]'
  | 'w-[55px]'
  | 'w-[65px]'
  | 'w-[75px]'
  | 'w-[100px]'
  | 'w-[150px]'
  | 'w-[200px]'
  | 'sm:w-[30px]'
  | 'sm:w-[35px]'
  | 'sm:w-[45px]'
  | 'sm:w-[55px]'
  | 'sm:w-[65px]'
  | 'sm:w-[75px]'
  | 'sm:w-[100px]'
  | 'sm:w-[150px]'
  | 'sm:w-[200px]'
  | 'md:w-[30px]'
  | 'md:w-[35px]'
  | 'md:w-[45px]'
  | 'md:w-[55px]'
  | 'md:w-[65px]'
  | 'md:w-[75px]'
  | 'md:w-[100px]'
  | 'md:w-[150px]'
  | 'md:w-[200px]'
  | 'vertNav:w-[30px]'
  | 'vertNav:w-[35px]'
  | 'vertNav:w-[45px]'
  | 'vertNav:w-[55px]'
  | 'vertNav:w-[65px]'
  | 'vertNav:w-[75px]'
  | 'vertNav:w-[100px]'
  | 'vertNav:w-[150px]'
  | 'vertNav:w-[200px]'

function _blankHeight(size: _Size) {
  switch (size) {
    case 'w-[30px]':
      return 'h-[calc(1.5*30px)]'
    case 'w-[35px]':
      return 'h-[calc(1.5*35px)]'
    case 'w-[45px]':
      return 'h-[calc(1.5*45px)]'
    case 'w-[55px]':
      return 'h-[calc(1.5*55px)]'
    case 'w-[65px]':
      return 'h-[calc(1.5*65px)]'
    case 'w-[75px]':
      return 'h-[calc(1.5*75px)]'
    case 'w-[100px]':
      return 'h-[calc(1.5*100px)]'
    case 'w-[150px]':
      return 'h-[calc(1.5*150px)]'
    case 'w-[200px]':
      return 'h-[calc(1.5*200px)]'
    case 'sm:w-[30px]':
      return 'sm:h-[calc(1.5*30px)]'
    case 'sm:w-[35px]':
      return 'sm:h-[calc(1.5*35px)]'
    case 'sm:w-[45px]':
      return 'sm:h-[calc(1.5*45px)]'
    case 'sm:w-[55px]':
      return 'sm:h-[calc(1.5*55px)]'
    case 'sm:w-[65px]':
      return 'sm:h-[calc(1.5*65px)]'
    case 'sm:w-[75px]':
      return 'sm:h-[calc(1.5*75px)]'
    case 'sm:w-[100px]':
      return 'sm:h-[calc(1.5*100px)]'
    case 'sm:w-[150px]':
      return 'sm:h-[calc(1.5*150px)]'
    case 'sm:w-[200px]':
      return 'sm:h-[calc(1.5*200px)]'
    case 'md:w-[30px]':
      return 'md:h-[calc(1.5*30px)]'
    case 'md:w-[35px]':
      return 'md:h-[calc(1.5*35px)]'
    case 'md:w-[45px]':
      return 'md:h-[calc(1.5*45px)]'
    case 'md:w-[55px]':
      return 'md:h-[calc(1.5*55px)]'
    case 'md:w-[65px]':
      return 'md:h-[calc(1.5*65px)]'
    case 'md:w-[75px]':
      return 'md:h-[calc(1.5*75px)]'
    case 'md:w-[100px]':
      return 'md:h-[calc(1.5*100px)]'
    case 'md:w-[150px]':
      return 'md:h-[calc(1.5*150px)]'
    case 'md:w-[200px]':
      return 'md:h-[calc(1.5*200px)]'
    case 'vertNav:w-[30px]':
      return 'vertNav:h-[calc(1.5*30px)]'
    case 'vertNav:w-[35px]':
      return 'vertNav:h-[calc(1.5*35px)]'
    case 'vertNav:w-[45px]':
      return 'vertNav:h-[calc(1.5*45px)]'
    case 'vertNav:w-[55px]':
      return 'vertNav:h-[calc(1.5*55px)]'
    case 'vertNav:w-[65px]':
      return 'vertNav:h-[calc(1.5*65px)]'
    case 'vertNav:w-[75px]':
      return 'vertNav:h-[calc(1.5*75px)]'
    case 'vertNav:w-[100px]':
      return 'vertNav:h-[calc(1.5*100px)]'
    case 'vertNav:w-[150px]':
      return 'vertNav:h-[calc(1.5*150px)]'
    case 'vertNav:w-[200px]':
      return 'vertNav:h-[calc(1.5*200px)]'
    default:
      noCase(size)
  }
}
