import { API } from '@indieocean/apidef'
import {
  Decoder,
  DecoderError,
  fail,
  succeed
} from '@mojotech/json-type-validation'
import { Result } from '@mojotech/json-type-validation/dist/types/result'
import _ from 'lodash'
import { RefObject, useRef, useState } from 'react'
import { useStrictMemo } from './UseStrictMemo'

export type InputControl<T = string> = {
  ref: RefObject<HTMLElement>
  validating: boolean
  setValidating: (x: boolean) => void
  initialValue: string
  value: string
  result: T | null
  setValue: (x: string) => void
  validation: Result<T, DecoderError>
}

export const useInputControl = (
  startingValue: string,
  validator: Decoder<string>,
  transform: (x: string) => string = x => x
): InputControl<string> =>
  _useInputControl(startingValue, validator, x => transform(x).trim())

export const useInputControlStrOrNull = (
  startingValue: string,
  validator: Decoder<string | null>,
  transform: (x: string) => string = x => x
): InputControl<string | null> =>
  _useInputControl(startingValue, validator, x => {
    const trimmed = transform(x).trim()
    return trimmed.length === 0 ? null : trimmed
  })

export const useInputControlArr = (
  startingValue: string,
  validator: Decoder<string[]>
): InputControl<string[]> =>
  _useInputControl(startingValue, validator, x =>
    x.split(',').map(x => x.trim())
  )

export const useInputControlNum = (
  startingValue: string,
  validator: Decoder<number>
): InputControl<number> =>
  _useInputControl(startingValue, validator, x => {
    if (x.trim().length === 0) throw new Error('cannot be empty')
    const r = _.toNumber(x)
    if (isNaN(r)) throw new Error('not a number')
    return r
  })

function _useInputControl<T>(
  startingValue: string,
  validator: Decoder<T>,
  transform: (x: string) => T
): InputControl<T> {
  const v2 = API.Decode.str('large').andThen(x => {
    try {
      const xt = transform(x)
      const result = validator.run(xt)
      return result.ok ? succeed(result.result) : fail<T>(result.error.message)
    } catch (e) {
      return fail<T>(e.message)
    }
  })

  const ref = useRef<HTMLElement>(null)
  const [validating, setValidating] = useState(false)
  const [value, setValue] = useState(startingValue)
  const [initialValue] = useState(startingValue)
  const validation = useStrictMemo(v2.run(value), [value])
  return {
    ref,
    validating,
    setValidating,
    value,
    result: validation.ok ? validation.result : null,
    initialValue,
    setValue,
    validation,
  }
}
