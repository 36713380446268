import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import { useURLParam } from '../../../Utils/UseURLParam'
import { GQLFetchError } from '../../Common/WithRelay'

export const useStoreSlugURLParam = () => {
  const storeSlug = fGet(useURLParam('storeSlug')).toLowerCase()
  const decoded = API.Decode.storeSlug().run(storeSlug)
  if (!decoded.ok) throw new GQLFetchError('NotFound')
  return decoded.result
}
