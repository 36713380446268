import { API } from '@indieocean/apidef'
import { fGet } from '@indieocean/utils'
import { useCombobox } from 'downshift'
import React, { useMemo, useState } from 'react'
import { Cover } from '../../Common/Cover'
import { SearchHighlight } from './Search/ProcessAlgoliaHighlight'
import { useSearchUserBooks } from './Search/UseSearchUserBooks'

export const BookSearch = React.memo(
  ({
    onCancel,
    onBook,
  }: {
    onCancel: () => void
    onBook: (book: API.SearchDataUserBook) => void
  }) => {
    const [term, setTerm] = useState('')
    const cleanTerm = useMemo(() => term.replace(/ by /g, ' '), [term])
    const resultsRaw = useSearchUserBooks(cleanTerm)
    const results = useMemo(
      () => (term === '' ? [] : resultsRaw),
      [term, resultsRaw]
    )

    const {
      isOpen: isSuggestOpen,
      selectedItem,
      getLabelProps,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox({
      items: results,
      itemToString: x =>
        x === null
          ? 'null'
          : `${x.book.info.title} by ${x.book.info.authors.join(', ')}`,
      onInputValueChange: ({inputValue}) => setTerm(inputValue ?? ''),
      onSelectedItemChange: ({selectedItem}) => onBook(fGet(selectedItem).book),
    })

    return (
      <div className="">
        <div className="" {...getComboboxProps({})}>
          <input
            {...getInputProps({
              className:
                'w-full border-b-2 border-inputBorder outline-none text-lg p-1 mt-3',
              placeholder: 'Search...',
            })}
          />
        </div>
        <ul {...getMenuProps({className: 'pt-2'})}>
          {results.map((result, index) => (
            <li
              className={`px-1 rounded-lg 
              ${index === highlightedIndex ? 'bg-sectionLightBG' : ''}`}
              key={`${result.book.bookId}`}
              {...getItemProps({item: result, index})}
            >
              <button
                className="py-2 grid grid-flow-col gap-x-2 justify-start text-left w-full"
                onClick={() => onBook(result.book)}
              >
                <Cover cover={result.book.info.cover} size="w-[30px]" />
                <div className="">
                  <h2 className="">
                    <_Highlight
                      notMatchClassName="lighten"
                      matchClassName="font-bold"
                    >
                      {result.highlighted.title}
                    </_Highlight>
                  </h2>
                  <h2 className="">
                    <span className="lighten-2">by</span>{' '}
                    {result.highlighted.authors.map((x, i) => (
                      <_Highlight
                        key={i}
                        notMatchClassName="lighten"
                        matchClassName="font-bold"
                      >
                        {x}
                      </_Highlight>
                    ))}
                  </h2>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
)

const _Highlight = React.memo(
  ({
    children,
    notMatchClassName,
    matchClassName,
  }: {
    children: SearchHighlight[]
    notMatchClassName: string
    matchClassName: string
  }) => {
    return (
      <>
        {children.map(({text, isMatch}, index) => (
          <span
            className={isMatch ? matchClassName : notMatchClassName}
            key={index}
          >
            {text}
          </span>
        ))}
      </>
    )
  }
)
