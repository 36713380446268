import { API } from '@indieocean/apidef'
import { AsyncReturnType, fGet } from '@indieocean/utils'
import { SearchIndex } from 'algoliasearch/lite'
import { useEffect, useState } from 'react'
import { useAlgoliaIndex } from '../../../Common/WithAlgoliaIndex'
import { useFUser } from '../../../Common/WithUser'
import { asyncEffect } from '../../../Utils/AsyncEffect'
import { processAlgoliaHighlight } from './ProcessAlgoliaHighlight'

export type SearchUserBooksHit = ReturnType<typeof _processHit>

export function useSearchUserBooks(term: string) {
  const {userId} = useFUser()
  const index = useAlgoliaIndex().userBooks
  const [result, setResult] = useState<SearchUserBooksHit[]>([])
  useEffect(() => {
    return asyncEffect(async status => {
      const result = await _searchIndex(index, term, userId)
      if (status.canceled) return
      setResult(result.hits.map(_processHit))
    })
  }, [index, term, userId])
  return result
}

const _searchIndex = (index: SearchIndex, term: string, userId: string) =>
  index.search<API.SearchDataUserBook>(term, {
    filters: `userId:${userId}`,
    hitsPerPage: 5,
    queryType: 'prefixAll',    
    restrictSearchableAttributes: ['info.title', 'info.authors'],
    minWordSizefor1Typo: 3,
    minWordSizefor2Typos: 6,
  })

function _processHit(hit: AsyncReturnType<typeof _searchIndex>['hits'][0]) {
  return {
    book: hit,
    highlighted: {
      title: processAlgoliaHighlight(fGet(hit._highlightResult?.info?.title)),
      authors: fGet(hit._highlightResult?.info?.authors)
        .map(fGet)
        .map(processAlgoliaHighlight),
    },
  }
}
