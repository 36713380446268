import Head from 'next/head'
import React, {ReactElement, ReactNode} from 'react'
import {graphql, useFragment} from 'react-relay'
import {GQL} from '../../../Generated/GQL/TypesFromSDL'
import {GQLFetchError} from '../WithRelay'
import {LocalizationCountryContext, useUser} from '../WithUser'
import {AppPage} from './AppPage'
import {HeaderSpec} from './Header'
import {
  isBasicStoreLive,
  useBasicStoreLive,
  WithBasicStoreLive,
} from './WithBasicStoreLive'
import {StorePage_query$key} from './__generated__/StorePage_query.graphql'

const fragment = graphql`
  fragment StorePage_query on Query {
    store(slug: $storeSlug) {
      id
      ...WithBasicStoreLive_store @relay(mask: false)
    }
    localizationCountry
  }
`

export default React.memo(
  ({
    children,
    queryDataKey,
    header,
    title = '',
    hideHorzNav = false,
  }: {
    children: ReactElement
    queryDataKey: StorePage_query$key
    header: HeaderSpec
    title?: string
    hideHorzNav?: boolean
  }) => {
    const data = useFragment(fragment, queryDataKey)
    const {localizationCountry} = data
    const store = (data as unknown as GQL.StorePage_Query).store

    if (!store || !isBasicStoreLive(store)) throw new GQLFetchError('NotFound')
    return (
      <WithBasicStoreLive store={store}>
        <LocalizationCountryContext.Provider value={localizationCountry}>
          <_AfterContext {...{header, children, title, hideHorzNav}} />
        </LocalizationCountryContext.Provider>
      </WithBasicStoreLive>
    )
  }
)

const _AfterContext = React.memo(
  ({
    children,
    header,
    title,
    hideHorzNav,
  }: {
    header: HeaderSpec
    children: ReactNode
    title: string
    hideHorzNav: boolean
  }) => {
    const store = useBasicStoreLive()
    const user = useUser()
    const selfStore =
      (user?.store?.data && user.store.slug === store.slug) ?? false

    const twitterUsername = store.data.links.twitter?.username
    return (
      <>
        <Head>
          <meta name="twitter:card" content="summary" />
          {twitterUsername && (
            <meta name="twitter:site" content={`@${twitterUsername}`} />
          )}
          <meta name="twitter:title" content={`${store.name} on IndieOcean`} />
          <meta
            name="twitter:description"
            content={`${
              store.data.introduction ?? `A personal bookstore by ${store.name}`
            }`}
          />
        </Head>
        <AppPage
          title={title}
          header={header}
          nav={{
            currentPage: selfStore ? 'Your Store' : 'Explore',
            hideHorz: hideHorzNav,
          }}
          showGoogleSignInPrompt
        >
          {children}
        </AppPage>
      </>
    )
  }
)
