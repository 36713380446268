import { useCallback, useState } from 'react'

export type BooleanStateObj = ReturnType<typeof useBooleanStateObj>
export function useBooleanStateObj(starting: boolean) {
  const [value, set] = useState(starting)
  const setTrue = useCallback(() => set(true), [])
  const setFalse = useCallback(() => set(false), [])
  const isTrue = value 
  const isFalse = !value
  return {value, set, setTrue, setFalse, isTrue, isFalse}
}
