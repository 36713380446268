import _ from 'lodash'
import React, { forwardRef, ReactElement } from 'react'
import { InputControl } from '../../Utils/UseInputControl'

export type TextInputProps = {
  className?: string
  label?: ReactElement
  placeholder?: string
  children?:
    | (string | undefined | false | null)
    | (string | undefined | false | null)[]
  disabled?: boolean
  type?: string
  autoComplete?: string
  control: InputControl<any>
  onFocus?: () => void
  onBlur?: () => void
  onNext?: () => void
  onAfterChange?: () => void
  validateOnBlur?: boolean
  ref?: React.RefObject<HTMLInputElement>
  customMessage?: {text: string; className: string}
}
export const TextInput = React.memo(
  forwardRef<HTMLInputElement, TextInputProps>(
    (
      {
        className,
        placeholder,
        children = [],
        disabled = false,
        control: {value, setValue, validating, setValidating, validation},
        onFocus,
        onBlur,
        onNext,
        onAfterChange,
        validateOnBlur = true,
        customMessage,
        label,
        type,
        autoComplete,
      }: TextInputProps,
      ref
    ) => {
      const message = (className: string, message: string) => (
        <div className="grid grid-flow-col gap-x-1 justify-start items-center">
          {label}
          <h3 className={`text-sm ${className}`}>{message}</h3>
        </div>
      )
      const detail = _.compact(_.flatten([children])).join('')
      return (
        <div className={className}>
          {validating && !validation.ok
            ? message('text-errorFG', `(${validation.error.message})`)
            : customMessage
            ? message(customMessage.className, customMessage.text)
            : message('opacity-0', 'hidden')}
          <input
            ref={ref}
            type={type ?? 'text'}
            autoComplete={autoComplete}
            className={` mt-1 w-full standard-input on-page-autofill`}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onFocus={onFocus}
            onChange={e => {
              setValue(e.target.value)
              if (onAfterChange) onAfterChange()
            }}
            onBlur={() => {
              if (validateOnBlur) setValidating(true)
              if (onBlur) onBlur()
            }}
            onKeyPress={e => e.key === 'Enter' && onNext && onNext()}
          />

          {detail && <p className="font-karla lighten-2 mt-1">{detail}</p>}
        </div>
      )
    }
  )
)
