/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StorePage_query = {
    readonly store: {
        readonly id: string;
        readonly user: {
            readonly id: string;
            readonly userId: string;
        };
        readonly name: string;
        readonly slug: string;
        readonly data: {
            readonly profileImage: string | null;
            readonly introduction: string | null;
            readonly links: {
                readonly twitter: {
                    readonly username: string;
                } | null;
            };
            readonly amazonAssociateIds: ReadonlyArray<{
                readonly country: API$CountryCode;
                readonly associateId: string;
            }>;
            readonly follows: {
                readonly id: string;
                readonly aggregate: {
                    readonly id: string;
                    readonly followsWhereIsSrcCount: number;
                    readonly followsWhereIsTargetCount: number;
                };
                readonly relativeToOther: {
                    readonly isOtherFollowsSrc: boolean;
                    readonly isOtherFollowsTarget: boolean;
                    readonly selfFollowTargetsInOtherFollowTargets: number;
                    readonly selfFollowSrcsInOtherFollowTargets: number;
                } | null;
            };
        } | null;
    } | null;
    readonly localizationCountry: API$CountryCode | null;
    readonly " $refType": "StorePage_query";
};
export type StorePage_query$data = StorePage_query;
export type StorePage_query$key = {
    readonly " $data"?: StorePage_query$data;
    readonly " $fragmentRefs": FragmentRefs<"StorePage_query">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "storeSlug"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorePage_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "storeSlug"
        }
      ],
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "store",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "introduction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreLinks",
              "kind": "LinkedField",
              "name": "links",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreLinksTwitter",
                  "kind": "LinkedField",
                  "name": "twitter",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AmazonAssociateId",
              "kind": "LinkedField",
              "name": "amazonAssociateIds",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "country",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "associateId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreFollows",
              "kind": "LinkedField",
              "name": "follows",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreFollowsAggregate",
                  "kind": "LinkedField",
                  "name": "aggregate",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followsWhereIsSrcCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followsWhereIsTargetCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "userId",
                      "variableName": "userId"
                    }
                  ],
                  "concreteType": "StoreFollowsRelativeToOther",
                  "kind": "LinkedField",
                  "name": "relativeToOther",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isOtherFollowsSrc",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isOtherFollowsTarget",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "selfFollowTargetsInOtherFollowTargets",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "selfFollowSrcsInOtherFollowTargets",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizationCountry",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '355506d0f94f2b3552fec97c2a42808f';
export default node;
